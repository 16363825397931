<template>
  <v-container fluid>
    <template>
      <v-card class="px-5 py-10 mx-10 my-5">
        <div>
          <v-tabs
            v-model="tab"
            class="mx-lg-10"
            color="purple"
          >
            <v-tab
              key="PROFILE"
              @change="onChangeTabs('PROFILE')"
            >
              PROFIL PENGGUNA
            </v-tab>

            <v-tab
              key="PASSWORD"
              @change="onChangeTabs('PASSWORD')"
            >
              UBAH PASSWORD
            </v-tab>
          </v-tabs>

          <v-tabs-items
            v-model="tab"
            touchless
          >
            <v-tab-item
              key="PROFILE"
              :eager="true"
            >
              <user-profile ref="PROFILE" />
            </v-tab-item>

            <v-tab-item
              key="PASSWORD"
              :eager="true"
            >
              <change-password ref="PASSWORD" />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-card>
    </template>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    components: {
      UserProfile: () => import('./UserProfile'),
      ChangePassword: () => import('./ChangePassword'),
    },

    data: () => ({
      tab: null,
    }),

    computed: {
      ...mapState({

      }),
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
      },

      onChangeTabs (key) {
        switch (key) {
          case 'ALL':
            this.$refs.ALL.initialize()
            break
          case 'NEW':
            this.$refs.NEW.initialize()
            break
          case 'ACC':
            this.$refs.ACC.initialize()
            break
          case 'REJECT':
            this.$refs.REJECT.initialize()
            break
          case 'DONE':
            this.$refs.DONE.initialize()
        }
      },
    },
  }
</script>

<style scope>
</style>
